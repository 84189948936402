import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useTranslation, Trans } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import StudentRegisterForm from 'src/components/register/StudentRegisterForm'
import heroRegisterStudent from 'src/images/register/hero-register-student.jpg'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function RegisterStudent(props) {
    const { t } = useTranslation();
    const { setTheme } = useContext(ThemeContext);
    const { registerTeacher } = useLocalizedRoutes();

    useEffect(() => setTheme('normal'), []);

    return (
        <VisitorLayout {...props} hero={heroRegisterStudent} title={t('student.register.title')}>
            <div className="px-6 lg:px-0">
                <Heading type='sub-title'>{t('student.register.title')}</Heading>
                <p className="mt-4">
                    <Trans i18nKey="student.register.intro">
                        Register as a student? <a href={registerTeacher} className="text-dark-blue-600 underline">Click here</a>
                    </Trans>
                </p>
            </div>
            <StudentRegisterForm />
        </VisitorLayout>

    )
}
