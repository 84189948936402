import React, { useContext, useState } from 'react'
import { navigate, Link } from 'gatsby'
import { useTranslation, Trans } from 'react-i18next'
import { useLocation } from '@reach/router'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import usePreferences from 'src/hooks/usePreferences'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import Input, { DateInput } from 'src/components/forms/Input'
import Checkbox from 'src/components/forms/Checkbox'
import Select from 'src/components/forms/Select'
import { SubmitButton } from 'src/components/buttons/Primary'
import { languages } from 'src/tempData'
import { countries } from 'src/util/countries'
import { fromDateFormat } from '../../api';

export default function StudentRegisterForm({redirectUrl, completionAction}) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { setOAuthTokens, state: {profile} } = useContext(ProfileContext);
    const { dateFormat } = usePreferences();
    const { convertToDateFormat, registerStudent, setUser, editRegisterStudent } = useVisitorApi();
    const { confirmEmailStudent, addStudentDetails, privacyPolicy } = useLocalizedRoutes();
    const [defaultCountry, setDefaultCountry] = useState(profile ? countries.find(country => country.value === profile.country_code) : '');
    const [defaultLocale, setDefaultLocale] = useState(profile ? languages.find(locale => locale.value === profile.locale) : '');
    const baseUrl = process.env.API_BASE_URL;

    const initialValues = {
        firstName: profile ? profile.first_name : '',
        lastName: profile ? profile.last_name : '',
        email: profile ? profile.email : '',
        phone: profile ? profile.phone : '',
        dateOfBirth: profile && profile.date_of_birth ? fromDateFormat(profile.date_of_birth).toFormat(dateFormat) : '',
        country_code: profile ? profile.country_code : '',
        postalCode: profile ? profile.postal_code : '',
        locale: profile ? profile.locale : '',
        password: '',
        passwordConfirmation: '',
        agreeToTerms: false,
        agreeToPrivacyPolicy: false,
        redirectUrl: redirectUrl || location.origin + addStudentDetails
    };
    const {
        values,
        setValue,
        onChangeValue,
        onChangeValueByName,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });
    const COL = 'px-4 w-full lg:w-6/12 mt-4';
    const COL_FULL = 'px-4 w-full mt-6';


    async function onSubmit(values) {
        if (profile) {
            values.locale = profile.locale;
            delete values.profile_picture;
            return await editRegisterStudent({
                ...values,
                id: profile.id,
                date_of_birth: convertToDateFormat(values.dateOfBirth, dateFormat),
            });
        }

        return await registerStudent({
            ...values,
            dateOfBirth: convertToDateFormat(values.dateOfBirth, dateFormat),
        });
    }

    function onRegistrationCompleted({ accessToken, refreshToken }) {
        setOAuthTokens({ accessToken, refreshToken })
        setUser(accessToken)
        if (completionAction) return completionAction(values.email)
        else navigate(confirmEmailStudent, { state: { values } });
    }

    function isFacebookApp() {
        if (typeof window === 'undefined') {
            return false;
        }

        var ua = window.navigator.userAgent || window.navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    return isFacebookApp() ? (
        <div className="w-full md:w-25 px-6 lg:px-0 mt-4 md:mb-8 lg:my-10">
            <div className="py-4 mb-4 border-t border-b border-error">
                <h1 className="font-bold">{t('register.facebookInApp.title')}</h1>
                <p>{t('register.facebookInApp.text')}</p>
            </div>
            <div className="text-right">
                <p>{t('register.facebookInApp.howto')}</p>
            </div>
        </div>
    ) : (
        <form
            className="w-full md:w-25 px-6 lg:px-0 mt-4 md:mb-8 lg:my-10"
            onSubmit={submit}
        >
            <div className="flex flex-wrap items-start -mx-4">
                <div className={COL}>
                    <Input
                        isRequired
                        name="firstName"
                        value={values.firstName}
                        error={errors.firstName}
                        label={t('general.forms.firstName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="lastName"
                        value={values.lastName}
                        error={errors.lastName}
                        label={t('general.forms.lastName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="email"
                        value={values.email}
                        error={errors.email}
                        label={t('general.forms.email')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="phone"
                        value={values.phone}
                        error={errors.phone}
                        label={t('general.forms.phone')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <DateInput
                        isRequired
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        error={errors.dateOfBirth}
                        label={t('general.forms.dateOfBirth')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        isRequired
                        isFormSelect
                        id="country"
                        name="country"
                        defaultValue={defaultCountry}
                        data={countries}
                        label={t('general.forms.country')}
                        placeholder={t('general.forms.countryPlaceholder')}
                        onChange={(item) => setValue('country_code', item.value)}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="postalCode"
                        value={values.postalCode}
                        error={errors.postalCode}
                        label={t('general.forms.postalCode')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        isRequired
                        isFormSelect
                        id="language"
                        name="language"
                        defaultValue={defaultLocale}
                        data={languages}
                        label={t('general.forms.language')}
                        placeholder={t('general.forms.languagePlaceholder')}
                        onChange={(lang) => setValue('locale', lang.value)}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        type="password"
                        name="password"
                        value={values.password}
                        error={errors.password}
                        label={t('general.forms.password')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                        error={errors.passwordConfirmation}
                        label={t('general.forms.passwordConfirmation')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL_FULL}>
                    <Checkbox
                        label={
                            <Trans i18nKey="general.forms.agreeToTerms">
                                I agree with the <a href={`${baseUrl}/Whambam-Terms&Conditions-${i18n.language}.pdf`} className="text-dark-blue-600 underline">Terms & Conditions</a>
                            </Trans>
                        }
                        isChecked={values.agreeToTerms}
                        error={errors.agreeToTerms}
                        onChange={onChangeValueByName('agreeToTerms')}
                    />
                    <Checkbox
                        label={
                            <Trans i18nKey="general.forms.agreeToPrivacy">
                                I agree with the <a href={privacyPolicy} className="text-dark-blue-600 underline">Privacy Policy</a>
                            </Trans>
                        }
                        isChecked={values.agreeToPrivacyPolicy}
                        error={errors.agreeToPrivacyPolicy}
                        onChange={onChangeValueByName('agreeToPrivacyPolicy')}
                    />
                </div>
                <div className={COL_FULL}>
                    <SubmitButton
                        isLoading={isLoading}
                    >
                        {t('student.register.submit')}
                    </SubmitButton>
                </div>
            </div>
        </form>
    )
}
